* {
    margin: 0;
    padding: 0;
}


/* On screens that are 992px or less, set the background color to blue */
@media screen and (max-width: 600px) {

    html,
    .body {
        height: 100vh;
        font-family: 'Monion Pro';
    }

    .container {
        height: auto;
    }

    model-viewer {
        width: 100vw;
        height: 90vw;
        --poster-color: transparent;
        margin: 0;
        border: 0;
        z-index: 100;
    }

    .body-container {
        position: relative;
        text-align: end;
        display: table-column;
        max-width: 90vw;
        font-family: 'Helvetica', sans-serif;
    }

    .body-container-left {
        position: relative;
        text-align: start;
        display: table-column;
        max-width: 90vw;
        font-family: 'Helvetica', sans-serif;
    }

    h1 {
        font-family: 'Minion Pro', serif;
        font-size: 25vw;
        -webkit-text-stroke-width: 3px;
        -webkit-text-fill-color: transparent;
        -webkit-text-stroke-color: black;
        justify-content: right;
    }

    h2 {
        font-size: 4vw;
        padding-bottom: 2vh;
        color: white;
    }


    h3 {
        font-family: 'Minion Pro', serif;
        font-size: 20vw;
        line-height: 17vw;
        -webkit-text-stroke-width: 2px;
        -webkit-text-fill-color: black;
        -webkit-text-stroke-color: black;
        justify-content: right;
    }

    h4 {
        font-size: 6vw;
        color: black;
        padding-bottom: 2vh;
    }

    p {
        font-size: 4vw;
        line-height: 1.25;
        padding-bottom: 2vh;
        text-align: justify;
        color: white;
    }
    .p-intro
    {
        font-size: 4vw;
        line-height: 1.25;
        color: black;
        padding-bottom: 2vh;
        text-align: justify;
    }
    .p-names
    {
        font-size: 3vw;
        line-height: 1.25;
        color: black;
        padding-bottom: 2vh;
        text-align: justify;
    }

    .p-dev-names
    {
        font-size: 2.75vw;
        line-height: 1.25;
        color: black;
        padding-bottom: 2vh;
        text-align: justify;
    }

    .names {
        text-align: start;
        padding: 0;
        font-size: 2.75vw;
        line-height: 1.25;
        color: white;
    }

    .names-left {
        text-align: end;
        padding: 0;
        font-size: 2.75vw;
        line-height: 1.25;
        color: white;
    }

    .keyword-container {
        text-align: end;
        display: none;
        padding-top: 2vh;
    }

    .keyword-container-left {
        text-align: end;
        display: none;
        padding-top: 2vh;
    }

    .section {
        display: flex;
        align-items: center;
        height: 100vh;
        position: relative;
        color: #ffeded;
        font-size: 7vmin;
        padding-top: 0;
        padding-left: 7%;
        padding-right: 7%;
        justify-content: center;
        flex-direction: column;
        align-content: space-between;
        flex-wrap: nowrap;
    }


    .section-two {
        display: flex;
        align-items: center;
        height: 100vh;
        position: relative;
        color: #ffeded;
        font-size: 7vmin;
        padding-top: 0;
        padding-left: 7%;
        padding-right: 7%;
        justify-content: center;
        flex-direction: column-reverse;
        align-content: space-between;
        flex-wrap: nowrap;
    }

    .intro-section {
        display: flex;
        align-items: center;
        height: 100vh;
        position: relative;
        font-size: 7vmin;
        padding-left: 7%;
        padding-right: 7%;
        will-change: transform;
        font-family: Helvetica, sans-serif;
        
    }

    .spacer{
        height: 50px;
    }
    .fab
    {
        background-color: forestgreen;
    }
    a:link{
        color: white;
    }

    #ar-button {
        background-image: url(../static/button-xx.png);
        background-repeat: no-repeat;
        background-size: 40px 40px;
        position: absolute;
        right: 20px;
        bottom: 10px;
        padding-bottom: 40px;
        background-color: transparent;
        height: 40px;
        width: 40px;
        border: transparent;
      }

      #ar-button:active {
        background-image: url(../static/button-grey-xx.png);
      } 

      #ar-button:focus {
        background-image: url(../static/button-grey-xx.png);
      }
    
      #ar-button:focus-visible {
        background-image: url(../static/button-grey-xx.png);
      }


}


@media screen and (min-width: 601px) {

    html,
    .body {
        height: 100vh;
        font-family: 'Monion Pro';
    }


    .section {
        display: flex;
        align-items: center;
        height: 100vh;
        position: relative;
        color: white;
        font-size: 7vmin;
        padding-left: 7%;
        padding-right: 7%;
        will-change: transform;
        justify-content: space-around;
    }

    .section-two {
        display: flex;
        align-items: center;
        height: 100vh;
        position: relative;
        color: white;
        font-size: 7vmin;
        padding-left: 7%;
        padding-right: 7%;
        will-change: transform;
        justify-content: space-around;
    }

    .intro-section {
        display: flex;
        align-items: center;
        height: 100vh;
        position: relative;
        font-size: 7vmin;
        padding-left: 7%;
        padding-right: 7%;
        will-change: transform;
    }

    .section-container {
        margin: 5%;

    }

    model-viewer {
        width: 50vw;
        height: 50vw;
        --poster-color: transparent;
        margin: 0;
        border: 0;
        z-index: 100;
        padding-right: 10%;
        justify-content: flex-start;
    }

    .model-viewer-right {
        width: 50vw;
        height: 50vw;
        --poster-color: transparent;
        margin: 0;
        border: 0;
        z-index: 100;
        padding-right: 0;
        padding-left: 10%;
        justify-content: flex-start;
    }

    .body-container {
        position: relative;
        text-align: end;
        max-width: 40vw;
        font-family: 'Helvetica', sans-serif;
    }

    .body-container-left {
        position: relative;
        text-align: start;
        max-width: 40vw;
        font-family: 'Helvetica', sans-serif;
    }

    .title-container-left {
        position: relative;
        text-align: start;
        max-width: 50vw;
        font-family: 'Helvetica', sans-serif;
    }

    .keyword-container {
        text-align: end;
        padding-top: 2vh;
    }

    .keyword-container-left {
        text-align: start;
        padding-top: 2vh;
    }

    h1 {
        font-family: 'Minion Pro', serif;
        font-size: 15vw;
        -webkit-text-stroke-width: 5px;
        -webkit-text-fill-color: transparent;
        -webkit-text-stroke-color: green;
        justify-content: right;
    }

    h2 {
        font-size: 1.5vw;
        color: white;
        padding-bottom: 2vh;
    }

    h3 {
        font-family: 'Minion Pro', serif;
        font-size: 12vw;
        line-height: 9vw;
        -webkit-text-stroke-width: 3px;
        -webkit-text-fill-color: transparent;
        -webkit-text-stroke-color: black;
        justify-content: right;
    }

    h4 {
        font-size: 2.75vw;
        color: black;
        padding-bottom: 2vh;
    }

    p {
        font-size: 1vw;
        line-height: 1.25;
        padding-bottom: 2vh;
    }

    .p-dev-names{
        font-size: 0.75vw;
        line-height: 1.25;
        padding-bottom: 2vh;
    }

    .names {
        text-align: start;
        padding: 0;
        font-size: 1vw;
        line-height: 1.25;
        color: white;
    }

    .names-left {
        text-align: end;
        padding: 0;
        font-size: 1vw;
        line-height: 1.25;
    }
    
    .spacer{
        height: 50px;
    }

    a:link{
        color: white;
    }
}

.webgl {
    position: fixed;
    top: 0;
    left: 0;
    outline: none;
}